import PropTypes from "prop-types";

export const AppStatus = {
  loading: "loading",
  error: "error",
  ready: "ready",
  active: "active",
  finished: "finished",
};

export const FormTypes = {
  DarkMode: "darkmode",
  StepperList: "stepperlist",
};

export const ButtonSelectionPropTypes = {
  answer: PropTypes.shape({
    value: PropTypes.string.isRequired,
    color: PropTypes.string,
    icon: PropTypes.string,
    supText: PropTypes.string,
  }),
  isAnswerSelected: PropTypes.func.isRequired,
  changeAnswer: PropTypes.func.isRequired,
};

export const RadioButtonImagePropTypes = {
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
};

export const ValidationPropTypes = PropTypes.shape({
  isValid: PropTypes.bool,
  error: PropTypes.string,
});
